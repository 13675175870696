import * as React from "react"
import { Link, graphql, navigate } from "gatsby"
import { useEffect } from 'react';
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {

  // useEffect(() => {
  //     console.log("Redirecting...");
  //     navigate("/socials/oahu");

  //   }, [location.search]);

  return (
    <Layout location={location} title="Social Dance Listings">
      <Seo title="Social Dance Today - Find Social Dance Events"
      description="Up-to-date social dance listings. Find socials in your area to dance salsa, bachata, zouk, and more."
      />

      <p>Browse regions to find social dancing in your area (where to dance salsa, bachata, zouk and more).</p>

      <h2>Regions</h2>
      <ol style={{ listStyle: `none` }}>
        <li key="los-angeles">
          <Link to={"/socials/los-angeles"}>
            <span itemProp="headline">Los Angeles</span>
          </Link>
        </li>
        <li key="oahu">
          <Link to={"/socials/oahu"}>
            <span itemProp="headline">Oahu</span>
          </Link>
        </li>
        <li key="orange-county">
          <Link to={"/socials/orange-county"}>
            <span itemProp="headline">Orange County</span>
          </Link>
        </li>
        <li key="oahu">
          <Link to={"/socials/san-diego"}>
            <span itemProp="headline">San Diego</span>
          </Link>
        </li>
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
